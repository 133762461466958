/**
 *
 */
export class NextBG
{
  public static texX:number = 320;
  public static texY:number = 128;
  public static texWidth:number = 160;
  public static texHeight:number = 160;

  // constructor(texX: number, texY: number, texWidth: number, texHeight: number) {
  //   this.texX = texX;
  //   this.texY = texY;
  //   this.texWidth = texWidth;
  //   this.texHeight = texHeight;
  // }
  //
  // constructor() {
  //   super(480, 480, 160, 160);
  // }
}