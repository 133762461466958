
export class SpecialBG
{
  public static texX:number = 320;
  public static texY:number = 96;
  public static texWidth:number = 320;
  public static texHeight:number = 32;
  //
  // constructor() {
  //   super(320, 96, 320, 32);
  // }
}